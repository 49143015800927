import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-5905_HD/NavButtons';
import TableFeatures from 'components/Outdoor_Cameras/IN-5905_HD/TableFeatures';
import CompareOutdoor from 'components/Products/Compare/CompareOutdoorFlyout';
import CompareTable from 'components/Outdoor_Cameras/IN-5907_HD/TableCompare';
import CompareGallery from 'components/Outdoor_Cameras/IN-5907_HD/GalleryCompare';
import OutdoorData from 'components/Products/Flyouts/Graphs/OutdoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in5905-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-5905 HD",
  "path": "/Outdoor_Cameras/IN-5905_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-5905 HD Outdoor IP Camera",
  "image": "../P_SearchThumb_IN-5905HD.png",
  "social": "/images/Search/P_SearchThumb_IN-5905HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-5905HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-5905 HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-5905 HD Outdoor IP Camera' image='/images/Search/P_SearchThumb_IN-5905HD.png' twitter='/images/Search/P_SearchThumb_IN-5905HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-5905_HD/' locationFR='/fr/Outdoor_Cameras/IN-5905_HD/' crumbLabel="IN-5905" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-5905-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-5905-hd",
        "aria-label": "in 5905 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-5905 HD`}</h1>
    <CompareOutdoor mdxType="CompareOutdoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/710bf12e72f6f636108109a50c195f69/573d3/IN-5905_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGElEQVQoz6VSPU/DMBTMTwSWDl0Y2PKjPBYJxMxApAzQtKVSkejSiA6kthIlURxRJY7t5xeUD1VFpF1649l393zPVn0BrEHWGIOI/3nE2hzxVkvh8VVElFIOmoIxUumBZMS6s6iqSgjRjwCgpAQARNRaF0UBAH/EjLE4jo0xoiyFEJzzNGnQRIHmPIuicL3+9H0fEdMkjZNYa42IjdhxHNu2PW+apsnG3wRBwBildNfZK6XyPN9uv6Io3O9/CCGTyf1q9dEnz9+XV9c3hBDKaECphh6Dz3Zd15stdHvaiIVUr4tlGIVSgwQzVHNfx9t0Phrfjca3D49PRVG2bR82hAgnltSB8/x7F7re7Nl5yTJuHYzPaE59kl/RMjom8C3voQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/710bf12e72f6f636108109a50c195f69/e4706/IN-5905_Overview_width1650.avif 230w", "/en/static/710bf12e72f6f636108109a50c195f69/d1af7/IN-5905_Overview_width1650.avif 460w", "/en/static/710bf12e72f6f636108109a50c195f69/7f308/IN-5905_Overview_width1650.avif 920w", "/en/static/710bf12e72f6f636108109a50c195f69/e1c99/IN-5905_Overview_width1650.avif 1380w", "/en/static/710bf12e72f6f636108109a50c195f69/e02f7/IN-5905_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/710bf12e72f6f636108109a50c195f69/a0b58/IN-5905_Overview_width1650.webp 230w", "/en/static/710bf12e72f6f636108109a50c195f69/bc10c/IN-5905_Overview_width1650.webp 460w", "/en/static/710bf12e72f6f636108109a50c195f69/966d8/IN-5905_Overview_width1650.webp 920w", "/en/static/710bf12e72f6f636108109a50c195f69/445df/IN-5905_Overview_width1650.webp 1380w", "/en/static/710bf12e72f6f636108109a50c195f69/44758/IN-5905_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/710bf12e72f6f636108109a50c195f69/81c8e/IN-5905_Overview_width1650.png 230w", "/en/static/710bf12e72f6f636108109a50c195f69/08a84/IN-5905_Overview_width1650.png 460w", "/en/static/710bf12e72f6f636108109a50c195f69/c0255/IN-5905_Overview_width1650.png 920w", "/en/static/710bf12e72f6f636108109a50c195f69/b1001/IN-5905_Overview_width1650.png 1380w", "/en/static/710bf12e72f6f636108109a50c195f69/573d3/IN-5905_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/710bf12e72f6f636108109a50c195f69/c0255/IN-5905_Overview_width1650.png",
              "alt": "INSTAR IN-5905 HD",
              "title": "INSTAR IN-5905 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-5905 HD is weather proof and wireless outdoor camera. Built-in, high-powered infrared diodes allow you to see deep into the dark. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`MicroSD Memory Card`}</Link>{` with comparably small size but best picture results. The sturdy construction of the camera body guarantees maximum durability and is weather proof for outdoor use.`}</p>
    <p>{`For surveillance purposes, the IN-5905 HD is ready for implementation to your existing system. But also as a stand-alone device it offers you a number of alarming features like `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Email Notification`}</Link>{` or compatibility with `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Signalling Devices`}</Link>{`. The WPS function allows you to connect the camera to your WiFi network with a press of a button. Accessing your camera from the internet is even easier. The Point-2-Point connection can be established by scanning a QR code with our smartphone app. The InstarVision mobile app is available for Android, iOS and Windows Phone 8.`}</p>
    <h3 {...{
      "id": "in-5905-hd-vs-in-5905-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#in-5905-hd-vs-in-5905-hd",
        "aria-label": "in 5905 hd vs in 5905 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-5905 HD vs IN-5905 HD`}</h3>
    <CompareTable mdxType="CompareTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <CompareGallery mdxType="CompareGallery" />
    <h4 {...{
      "id": "comparison-between-in-5905-hd-and-in-5905-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#comparison-between-in-5905-hd-and-in-5905-hd",
        "aria-label": "comparison between in 5905 hd and in 5905 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comparison between IN-5905 HD and IN-5905 HD`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Slide 1`}</strong>{`: The camera model IN-5905 HD offers a wide angle lense and should be used at close distance to the area under surveillance - the model is ideal to be installed in entrance areas.`}</li>
      <li parentName="ul"><strong parentName="li">{`Slide 2`}</strong>{`: The wide angle lense enables you to keep an eye over a wide area. But by doubling the field of view on the 720p sensor (compared to the standard lense that is installed in the IN-5905 HD), you lose half of the details inside your image. It is recommend to use the IN-5905 HD for installation at larger distance.`}</li>
      <li parentName="ul"><strong parentName="li">{`Slide 3`}</strong>{`: Installing a big camera close to the area under surveillance, can be distracting. The model IN-5905 HD is constructed to be unobtrusive.`}</li>
    </ul>
    <OutdoorData mdxType="OutdoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 720p `}<Link to="/Outdoor_Cameras/IN-5905_HD/Technical_Specifications/" mdxType="Link">{`Omnivision CMOS Sensor`}</Link></li>
      <li parentName="ul">{`High quality IP65 weatherproof aluminium housing`}</li>
      <li parentName="ul">{`Nightvision with 5 Infrared LED´s @ 850nm (barely visible for the human eye) for distances up to 15 - 20m`}</li>
      <li parentName="ul">{`Alarm I/O for external sensors (e.g. `}<Link to="/Products/IN-Motion/" mdxType="Link">{`Motion`}</Link>{` or smoke detectors) and receivers (e.g. house automations)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with 2.4 GHz `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free DDNS ("Internet") Address and P2P User ID to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Many built-in features allow you to use this camera not only for surveillance, but also for your personal projects. The FTP Support allows you to use the IN-7011 HD as a multi-functional outdoor webcam for integration on your website.`}</p>
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      